import { useMemo } from 'react';

import { ApiCompetition } from '~/api/v2/competitions/get-type';
import { CardProjectDetail } from '~/components/Card/CardProjectDetail';
import SectionHeader from '~/components/SectionHeader/SectionHeader';

const Card = ({ competition }: { competition: ApiCompetition }) => {
  const status = useMemo(() => {
    if (competition.status === 'open') {
      return '募集中';
    } else if (competition.status === 'voting') {
      return '投票中';
    } else {
      return '終了';
    }
  }, [competition.status]);

  return (
    <CardProjectDetail
      link={`/comps/${competition.id}`}
      areaText={''}
      companyName={competition.corporate.name}
      companyLogo={competition.corporate.logo || '/icons/corp-icon.png'}
      text={competition.social_issue?.description || ''}
      title={competition.title || ''}
      src={competition.cover}
      label={'わくわく寄付コンペ'}
      status={status}
      recruitments={competition.social_issue ? [competition.social_issue.name] : []}
    />
  );
};

export const Competition = ({ competitions }: { competitions: ApiCompetition[] }) => {
  return (
    <div className='flex flex-col items-center gap-8'>
      <SectionHeader
        title='わくわく寄付コンペ'
        description='寄付でつながる社会貢献'
        href='/projects'
      />
      <div className='flex flex-col flex-wrap justify-center gap-4 lg:flex-row'>
        {competitions.map((competition, i) => (
          <Card key={i} competition={competition} />
        ))}
      </div>
    </div>
  );
};
