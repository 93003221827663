import LinkGroup from '~/components/LinkGroup/LinkGroup';

const linkGroupData = [
  {
    groupName: '北海道・東北',
    links: [
      { name: '北海道' },
      { name: '青森' },
      { name: '岩手' },
      { name: '宮城' },
      { name: '秋田' },
      { name: '山形' },
      { name: '福島' },
    ],
  },
  {
    groupName: '関東',
    links: [
      { name: '東京' },
      { name: '神奈川' },
      { name: '埼玉' },
      { name: '千葉' },
      { name: '群馬' },
      { name: '栃木' },
      { name: '茨城' },
    ],
  },
  {
    groupName: '東海',
    links: [{ name: '愛知' }, { name: '静岡' }, { name: '岐阜' }, { name: '三重' }],
  },
  {
    groupName: '信越・北陸',
    links: [
      { name: '新潟' },
      { name: '富山' },
      { name: '石川' },
      { name: '福井' },
      { name: '山梨' },
      { name: '長野' },
    ],
  },
  {
    groupName: '近畿',
    links: [
      { name: '大阪' },
      { name: '兵庫' },
      { name: '京都' },
      { name: '滋賀' },
      { name: '奈良' },
      { name: '和歌山' },
    ],
  },
  {
    groupName: '四国',
    links: [{ name: '愛媛' }, { name: '高知' }, { name: '徳島' }, { name: '香川' }],
  },
  {
    groupName: '中国',
    links: [
      { name: '鳥取' },
      { name: '島根' },
      { name: '岡山' },
      { name: '広島' },
      { name: '山口' },
    ],
  },
  {
    groupName: '九州・沖縄',
    links: [
      { name: '福岡' },
      { name: '佐賀' },
      { name: '長崎' },
      { name: '熊本' },
      { name: '大分' },
      { name: '宮崎' },
      { name: '鹿児島' },
      { name: '沖縄' },
    ],
  },
];

export const Area = () => {
  return (
    <div className='mb-8'>
      <div className='text-title-strong-x-small text-accent'>地域を支援</div>
      <div className='my-4 flex flex-row flex-wrap gap-4'>
        {linkGroupData.map((data, index) => (
          <LinkGroup
            key={index}
            groupName={data.groupName}
            links={data.links.map((d) => ({
              name: d.name,
              href: `/orgs?keyword=${d.name}`,
            }))}
          />
        ))}
      </div>
    </div>
  );
};
