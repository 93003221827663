import MainBanners from './mainBanners';

export const BottomBanners = () => {
  return (
    <>
      <MainBanners
        banners={[
          { alt: '社会課題一覧', link: '/social-issues/general-social-issues' },
          { alt: '地域課題一覧', link: '/social-issues/local-social-issues' },
        ]}
      />
      <MainBanners
        banners={[
          { alt: 'サステナ委員会', link: 'https://lp.ichicommons.com/iinkai' },
          { alt: 'サステナPress', link: 'https://press.ichicommons.com' },
        ]}
      />
    </>
  );
};
